import React from 'react';

import Layout from '../components/layout';
import styled from 'styled-components';

import Header from '../components/index/Header';
import Footer from '../components/Footer';

import IndexSlideshow from '../components/index/IndexSlideshow';

const ContentContainer = styled.div`
    position: absolute;
    margin-top: 10%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const BackgroundSlideshow = styled(IndexSlideshow)`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    opacity: 0.5;
`;

class IndexPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isArticleVisible: false,
            timeout: false,
            articleTimeout: false,
            article: '',
            loading: 'is-loading'
        };
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentDidMount() {
        this.timeoutId = setTimeout(() => {
            this.setState({ loading: '' });
        }, 100);
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    render() {
        return (
            <Layout location={this.props.location}>
                <BackgroundSlideshow className="background" />
                <ContentContainer className={`body ${this.state.loading}`}>
                    <Header timeout={this.state.timeout} />
                    <Footer />
                </ContentContainer>
            </Layout>
        );
    }
}

export default IndexPage;
