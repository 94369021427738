import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Menu, { SubMenu as _SubMenu, MenuItem as _MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';

const MenuContainer = styled.nav`
    width: 100%;
    background: transparent;

    @media (max-width: 768px) {
        width: auto;
    }
`;

const SubMenu = styled(_SubMenu)`
    cursor: pointer;
`;

const MenuItem = styled(_MenuItem)`
    & a {
        border-bottom-color: transparent;
    }

    &:hover a,
    & a:hover {
        border-bottom-color: rgba(255, 255, 255, 1);
    }
`;

const DesktopMenu = styled(Menu)`
    background: transparent;

    @media (max-width: 768px) {
        display: none !important;
    }
`;

const SocialMediaContainer = styled.div`
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-evenly !important;
    align-items: center !important;
    width: 100% !important;
    height: 100% !important;
`;

const SocialMediaLink = styled.a`
    text-decoration: none;
    border: 0 !important;
`;

const MobileMenu = styled.ul`
    display: flex;
    flex-direction: column;

    @media (min-width: 768px) {
        display: none !important;
    }
`;

const MobileMenuItem = styled.li`
    height: 40px;
    width: 100% !important;
    color: white;
`;

const MainTitle = styled.h1`
    font-size: 2.25rem;
    line-height: 1.3;
    letter-spacing: 0.5rem;
`;

const SubTitle = styled.p`
    @media (max-width: 768px) {
        font-size: 1rem !important;
    }
`;

const Header = props => (
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
        <div className="content">
            <div className="inner">
                <MainTitle>Guillaume Pannetier</MainTitle>
                <SubTitle>
                    Portrait lifestyle et studio / Mariage / Reportage
                    événementiel
                </SubTitle>
            </div>
        </div>
        <MenuContainer className="menu-container">
            <DesktopMenu className="menu-list" mode="horizontal">
                <SubMenu
                    className="gallery-submenu"
                    title={
                        <span className="submenu-title-wrapper">Galerie</span>
                    }
                    key="1"
                >
                    <MenuItem key="1-1">
                        <Link to="/gallery/portrait" title="Portrait">
                            Portrait
                        </Link>
                    </MenuItem>
                    <MenuItem key="1-2">
                        <Link to="/gallery/wedding" title="Mariage">
                            Mariage
                        </Link>
                    </MenuItem>
                    <MenuItem key="1-3">
                        <Link to="/gallery/dance" title="Danse">
                            Danse
                        </Link>
                    </MenuItem>
                    {/* <MenuItem key="1-4">
                        <Link to="/gallery/street">Divers</Link>
                    </MenuItem> */}
                </SubMenu>
                <MenuItem key="2">
                    <Link to="/contact" title="Me contacter">
                        Contact
                    </Link>
                </MenuItem>
                <MenuItem key="3">
                    <Link to="/about" title="à propos de moi">
                        À propos
                    </Link>
                </MenuItem>
                <MenuItem key="4" className="social-media-item">
                    <SocialMediaContainer>
                        <SocialMediaLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/guillaumepannetierphotography/"
                            title="Instagram"
                        >
                            <span>
                                <i className="fa fa-instagram" />
                            </span>
                        </SocialMediaLink>
                        <SocialMediaLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/guillaumepannetierphotography/"
                            title="Facebook"
                        >
                            <span>
                                <i className="fa fa-facebook" />
                            </span>
                        </SocialMediaLink>
                    </SocialMediaContainer>
                </MenuItem>
            </DesktopMenu>
            <MobileMenu>
                <MobileMenuItem>
                    <Link to="/gallery/portrait" title="Portrait">
                        Portrait
                    </Link>
                </MobileMenuItem>
                <MobileMenuItem>
                    <Link to="/gallery/dance" title="Danse">
                        Danse
                    </Link>
                </MobileMenuItem>
                <MobileMenuItem>
                    <Link to="/gallery/wedding" title="Mariage">
                        Mariage
                    </Link>
                </MobileMenuItem>
                <MobileMenuItem>
                    <Link to="/contact" title="Me contacter">
                        Contact
                    </Link>
                </MobileMenuItem>
                <MobileMenuItem>
                    <Link to="/about" title="A propos">
                        À propos
                    </Link>
                </MobileMenuItem>
                <MobileMenuItem>
                    <SocialMediaContainer>
                        <SocialMediaLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.instagram.com/guillaumepannetierphotography/"
                            title="Instagram"
                        >
                            <span>
                                <i className="fa fa-instagram" />
                            </span>
                        </SocialMediaLink>
                        <SocialMediaLink
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.facebook.com/guillaumepannetierphotography/"
                            title="Facebook"
                        >
                            <span>
                                <i className="fa fa-facebook" />
                            </span>
                        </SocialMediaLink>
                    </SocialMediaContainer>
                </MobileMenuItem>
            </MobileMenu>
        </MenuContainer>
    </header>
);

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
};

export default Header;
