import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

const SlideContainer = styled.div`
    width: 100%;
`;

const query = graphql`
    {
        allFile(
            filter: { relativeDirectory: { eq: "backgrounds" } }
            sort: { fields: [name] }
        ) {
            edges {
                node {
                    dir
                    modifiedTime
                    childImageSharp {
                        fixed(width: 800) {
                            aspectRatio
                            height
                            src
                            width
                            originalName
                        }
                    }
                }
            }
        }
    }
`;

const Fade = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
`;

const ImageContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    opacity: ${props => (props.active ? 1 : 0)};
    transition: opacity 2s ease-in-out;
    background-image: url(${props => props.bg});
    background-position: top;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
`;

const IndexSlideshow = ({ className }) => {
    const data = useStaticQuery(query);
    const pictures = data.allFile.edges.map(
        edge => edge.node.childImageSharp.fixed.src
    );

    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            const newIndex = (activeIndex + 1) % pictures.length;
            setActiveIndex(newIndex);
        }, 5000);

        return () => {
            clearTimeout(timer);
        };
    }, [activeIndex]);

    return (
        <SlideContainer className={`slide-container ${className}`}>
            <Fade className="fade-container">
                {pictures.map((picture, index) => {
                    return (
                        <ImageContainer
                            key={index}
                            bg={picture}
                            active={index === activeIndex}
                        />
                    );
                })}
            </Fade>
        </SlideContainer>
    );
};

IndexSlideshow.defaultProps = {
    className: ''
};

export default IndexSlideshow;
